<template>
  <div class="view">
    <video
      id="video"
      src="https://eage-cloud.oss-cn-hangzhou.aliyuncs.com/pro/tutorial/%E6%B0%B4%E6%B5%B8%E4%BC%A0%E6%84%9F%E5%99%A8.mp4"
      controls
      controlsList="nodownload noplaybackrate"
      disablePictureInPicture
    ></video>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      id: null,
      detail: {}
    }
  },

  filters: {},
  computed: {},
  watch: {},

  created() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.view {
  height: 100%;

  #video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
